import { Link } from "react-router-dom"
import NavBar from "./NavBar/NavBar"
import s from "./Header.module.css"

const Header = () => {
	return (
		<header className={s.header}>
      <Link to="/">
        <h1 role="banner">50-Max</h1>
      </Link>

      <NavBar />
		</header>
	)
}

export default Header
