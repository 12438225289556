import { ReactNode } from "react"
import s from "./SectionHeader.module.css"

interface IProps {
  children: ReactNode
}

const SectionHeader = ({ children }: IProps) => {
	return (
    <h2 className={s.sectionHeader}>
      {children}
    </h2>
	)
}

export default SectionHeader
