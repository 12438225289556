import { AxiosResponse } from "axios"
import { api } from "../../../../api"
import { useEffect, useState } from "react"
import { IExhibition } from "../../../../types"
import CardsGrid from "../../../shared/CardsGrid/CardsGrid"
import GridCard from "../../../shared/GridCard/GridCard"
import Header from "../../../shared/Header/Header"
import HeroHeader from "../../../shared/HeroHeader/HeroHeader"
import Loader from "../../../shared/Loader/Loader"
import SectionHeader from "../../../shared/SectionHeader/SectionHeader"
import s from "./Exhibitions.module.css"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet"

const ExhibitionsPage = () => {
  const [exhibitions, setExhibitions] = useState<IExhibition[] | null>(null)

  useEffect(() => {
    api
      .get("/exhibitions")
      .then((res: AxiosResponse<IExhibition[]>) => {
          setExhibitions(res.data)
        })
  }, [])

  return (
    <main className="container">
      <Header />

      <Helmet>
        <title>Выставки</title>
        <meta name="description" content="Ознакомьтесь с выставками, к которым мы приложили руку" />
      </Helmet>
      
      <HeroHeader>
        Мы работали с музеями достаточно долго, чтобы понять, что каждая выставка – это совокупность многих поставленных задач.<br />Мы решаем их все.
      </HeroHeader>

      <section className={s.exhibitionsSection}>
        <SectionHeader>
          Выставки
        </SectionHeader>
        
        {
          (Array.isArray(exhibitions) && exhibitions.length === 0) && (
            <h1 style={{textAlign: "center", fontSize:"2.6rem", fontWeight: "100", marginBlock: "3rem"}}>
              Извините,<br/>Не удалось получить информацию о выставках<br/>Повторите попытку позже
            </h1>
          )
        }

        {
          (exhibitions === null) && <Loader />
        }

        <CardsGrid>
          {exhibitions?.map(exib => {
            return (
              <Link to={exib.id.toString()} key={exib.id}>
                <GridCard
                  img={exib.main_photo || "/img/placeholder.jpg"}
                  name={exib.name}
                  desc={exib.description}
                  location={exib.location}
                  year={new Date(exib.year)}
                />
              </Link>
            )
          })}
        </CardsGrid>
      </section>
    </main>
  )
}

export default ExhibitionsPage
