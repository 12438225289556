import { ReactNode, memo, useRef, useEffect } from "react"
import CloseIcon from "../../../assets/icons/close.svg?react"
import s from "./Modal.module.css"

interface IModalProps {
  handleClose: () => void,
  children: ReactNode
}

const Modal = memo(({ handleClose, children }: IModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null)
  const handleEscape = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Escape") {
      handleClose()
    }
  }
  
  useEffect(() => {
    modalRef.current?.focus()
  }, [])

  return (
		<div
      ref={modalRef}
      tabIndex={0}
      className={s.modal}
      onClick={handleClose}
      onKeyUp={handleEscape}
    >
      <CloseIcon width="1.4rem" className={s.modalClose} />
      <div onClick={(e) =>{ e.stopPropagation()}} className={s.modalBody}>
        {children}
      </div>
		</div>
	)
})
export default Modal
