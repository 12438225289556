import s from "./GridCard.module.css"

interface IProps {
  img: string,
  name: string,
  desc: string,
  location: string,
  year: Date
}

const GridCard = ({img, name, desc, location, year}: IProps) => {
	return (
    <div className={s.card}>
      <img className={s.card__image} src={ img } alt={`${name}`}></img>
      <div className={s.card__caption}>
        <h2>{ name }</h2>
        {desc?.trim() && <p>{ desc }</p>}
      </div>
      <div className={s.card__meta}>
        <p>{ location }</p>
        <p>{ year?.getFullYear?.() || year.toString()}</p>
      </div>
    </div>
	)
}

export default GridCard
