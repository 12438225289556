import { AxiosResponse } from "axios"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { api } from "../../../../api"
import { IProject } from "../../../../types"
import Header from "../../../shared/Header/Header"
import Loader from "../../../shared/Loader/Loader"
import Modal from "../../../shared/Modal/Modal"
import s from "./Project.module.css"

const ProjectPage = () => {
  const { id } = useParams()
  const [data, setData] = useState<IProject | null>(null)
  const [modalImage, setModalImage] = useState<string | null>(null)

  useEffect(() => {
    api
      .get(`projects/${id}`)
      .then((response: AxiosResponse<IProject>) => {
        setData(response.data)
      })
  }, [])

  if (data === null) {
    return (
      <div className="container">
        <Header />
        <Loader />
		</div>
    )
  }

	return (
    <div className="container">
      <Header/>

      <div className={s.headers}>
        <h1>{data.name}</h1>
        <h2>{data.description}</h2>
      </div>
      <img src={data.main_photo || ""} className={s.mainImg} alt={`Проект ${data.name}`}/>
      <div className={s.details}>
        <div className={s.details__location}>
          <div>{data.location}</div>
          <div>{new Date(data.year).getFullYear() || data.year}</div>
        </div>
      </div>

      <hr className={s.hr}/>

      <div className={s.gallery}>
        {data.photos.map(p => (
          <img
            src={p.link || ""}
            key={p.id}
            onClick={() => setModalImage(p.link)}
          />
        ))}
      </div>

        {!!modalImage && (
          <Modal handleClose={() => setModalImage(null)}>
            <div>
              <img src={modalImage || "/img/placeholder.jpg"}/>
            </div>
          </Modal>
        )}
		</div>
	)
}

export default ProjectPage
