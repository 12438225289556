import { Link, useParams } from "react-router-dom"
import pagesData from "../../../../data/detailedPages.json"
import Header from "../../../shared/Header/Header"
import Image from "../../../shared/Image/Image"
import s from "./DetailedPage.module.css"

const DetailedPage = () => {
  const { path } = useParams()
  const pageData = pagesData.find(c => c.path === path)

  if (!pageData) return <>
    <h1 className="mt-32 text-4xl text-center">Извините, данной страницы не существует</h1>
    <Link to="/" className="mt-6 block text-center text-3xl text-blue-600 cursor-pointer">← На Главную ←</Link>
  </>

	return (
		<>
			<div className="container">
        <Header />
      </div>

      <div className="max-w-[1800px] mx-auto">
        <h1 className={s.title}>{pageData.title}</h1>
        <p className={s.hero_caption}>
          {pageData.captions[0]}
        </p>

        <Image
          className={s.full_image}
          srcSetObj={pageData.mainImageSrcSet}
        />

        <p className={`${s.caption}`}>
          {pageData.captions[1]}
        </p>

        {/* In case if there is only one image for the gallery */}
        <div className={pageData.gallerySrcSets.length > 1 ? s.gallery : s.gallery_single_col}>
          {
            pageData.gallerySrcSets.map((set, index) => (
              <Image
                key={`${Object.values(set)[0]}_${index}`}
                srcSetObj={set}
              />
            ))
          }
        </div>
      </div>
		</>
	)
}

export default DetailedPage
