import s from "./Constructions.module.css"
import Header from "../../../shared/Header/Header"
import ImageCardAccent from "../../../shared/ImageCardAccent/ImageCardAccent"
import ImageHero from "../../../shared/ImageHero/ImageHero"
import { Helmet } from "react-helmet"

const ConstructionsPage = () => {
	return (
    <main className="container">
      <Header />

      <Helmet>
        <title>Конструкции</title>
      </Helmet>

      <ImageHero
        paragraphs={[
          "На каждой выставке посетителю важно чувствовать себя комфортно и удобно, чтобы понастоящему погрузиться в атмосферу и насладиться эстетикой искусства. Выставочное пространство должно быть так организовано, чтобы все экспонаты были представлены в наиболее выгодном свете и могли донести до своего зрителя максимум впечатлений. При этом должны быть соблюдены все требования по их защите и безопасности.",
          "При подходе к работе мы учитываем все эти аспекты. Оформление выставки включает в себя все необходимые этапы: планирование, изготовление и установку выставочных конструкций и витрин самого высокого качества, проверку их безопасности и устойчивости. На этапе проектирования наш многолетний опыт позволяет нам внедрять самые новые технологии и уникальные инженерные решения, чтобы воплотить в жизнь замысел художника или дизайнера пространства."
        ]}
        srcSetObj={{
          3840: "/img/constructions/1@3840.jpg",
          1920: "/img/constructions/1@1920.jpg",
          960: "/img/constructions/1@960.jpg",
        }}
      />

      <section className={s.cards}>
        <ImageCardAccent
          to="display-cases"
          srcSetObj={{
            3840: "/img/constructions/4@3840.jpg",
            1920: "/img/constructions/4@1920.jpg",
            960: "/img/constructions/4@960.jpg",
          }}
          header="Витрины"
          desc="Музейные витрины должны одновременно выполнять две различные функции: защищать и сохранять предметы искусства, которые они демонстрируют, и позволить зрителю максимально изучить и рассмотреть их"
        />

        <ImageCardAccent
          to="partitions"
          srcSetObj={{
            1000: "/img/constructions/2@1000.jpg",
          }}
          header="Перегородки, стены"
          desc="Стены на выставке выполняют сразу несколько задач: определяют и зонируют пространство, обеспечивают фон для объектов искусства, а также создают цветовую и стилистическую атмосферу"
        />

        <ImageCardAccent
          to="light-boxes"
          srcSetObj={{
            3400: "/img/constructions/5@3400.jpg",
            1920: "/img/constructions/5@1920.jpg",
            960: "/img/constructions/5@960.jpg",
          }}
          header="Лайтбоксы"
          desc="Создание световых акцентов формирует особую атмосферу и имеет решающее значение для восприятия зрителем  выставочного пространства"
        />

        <ImageCardAccent
          to="podiums"
          srcSetObj={{
            3840: "/img/constructions/8@3840.jpg",
            1920: "/img/constructions/8@1920.jpg",
            960: "/img/constructions/8@960.jpg",
          }}
          header="Подиумы"
          desc="Некоторые экспонаты выставки требуют отдельного размещения на платформе или основании, чтобы подчеркнуть их особенность и обратить внимание зрителей"
        />

        <ImageCardAccent
          to="interactive-solutions"
          srcSetObj={{
            3840: "/img/constructions/3@3840.jpg",
            1920: "/img/constructions/3@1920.jpg",
            960: "/img/constructions/3@960.jpg",
          }}
          header="Мультимедийные, интерактивные решения, иммерсивные конструкции"
          desc="Иногда, кроме привычного подхода к демонстрации экспонатов, выставка предполагает  произвести на посетителя оригинальное эмоциональное впечатление, используя для этого различные иммерсивные технологии"
        />

        <ImageCardAccent
          to="seats"
          srcSetObj={{
            3840: "/img/constructions/11@3840.jpg",
            1920: "/img/constructions/11@1920.jpg",
            960: "/img/constructions/11@960.jpg",
          }}
          header="Скамейки и сиденья"
          desc="На каждой выставке посетитель должен иметь возможность посидеть, отдохнуть и поразмышлять над увиденным"
        />

        <ImageCardAccent
          to="protective-structures"
          srcSetObj={{
            3840: "/img/constructions/13@3840.jpg",
            1920: "/img/constructions/13@1920.jpg",
            960: "/img/constructions/13@960.jpg",
          }}
          header="Защитные конструкции"
          desc="Некоторые объекты искусства требуют дополнительной защиты, при этом посетитель должен получить наиболее полные возможности для просмотра"
        />
      </section>
		</main>
	)
}

export default ConstructionsPage
