import { useEffect } from "react"
import { Route, Routes, useLocation } from "react-router-dom"
import AboutUsPage from "./components/pages/views/AboutUs/AboutUs"
import ConstructionsPage from "./components/pages/views/Constructions/Constructions"
import ContactsPage from "./components/pages/views/Contacts/Contacts"
import DetailedPage from "./components/pages/views/DetailedPage/DetailedPage"
import ExhibitionPage from "./components/pages/views/Exhibition/Exhibition"
import ExhibitionsPage from "./components/pages/views/Exhibitions/Exhibitions"
import InteriorPage from "./components/pages/views/Interior/Interior"
import MainPage from "./components/pages/views/Main/Main"
import ProjectPage from "./components/pages/views/Project/Project"
import ProjectsPage from "./components/pages/views/Projects/Projects"
import CookiesConfirm from "./components/shared/CookiesConfirm/CookiesConfirm"

const App = () => {
	return (
    <>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/constructions" element={<ConstructionsPage />} />
          <Route path="/interior" element={<InteriorPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/contacts" element={<ContactsPage />} />

          <Route path="/exhibitions">
            <Route path="" element={<ExhibitionsPage />} />
            <Route path=":id" element={<ExhibitionPage />} />
          </Route>

          <Route path="/projects">
            <Route path="" element={<ProjectsPage />} />
            <Route path=":id" element={<ProjectPage />} />
          </Route>

          <Route path="/constructions">
            <Route path=":path" element={<DetailedPage />} />
          </Route>

          <Route path="/interior">
            <Route path=":path" element={<DetailedPage />} />
          </Route>
        </Routes>
      </ScrollToTop>
      <CookiesConfirm />
    </>
	)
}

export default App

const ScrollToTop = (props: any) => {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return <>{props.children}</>
}