import React from "react"
import s from "./ImageHero.module.css"
import Image from "../Image/Image"

interface IProps {
  paragraphs: string[],
  srcSetObj: {[key: string]: string},
  className?: string
}

const ImageHero = ({paragraphs, srcSetObj, className}: IProps) => {
	return (
    <section className={`${s.hero} ${className || ""}`}>
      <div className={s.hero__imgeSection}>
        <p>
          {paragraphs.map(p => (
            <React.Fragment key={p.substring(0, 5)}>
              {p}<br/><br/> 
            </React.Fragment>
          ))}
        </p>
        <Image srcSetObj={srcSetObj}/>
      </div>
    </section>
	)
}

export default ImageHero
