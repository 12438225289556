import { ReactNode } from "react"
import s from "./HeroHeader.module.css"

interface IProps {
  children: ReactNode
}

const HeroHeader = ({ children }: IProps) => {
	return (
    <h1 className={s.hero}>
      { children }
    </h1>
	)
}

export default HeroHeader
