import { useForm } from "react-hook-form"
import emailjs from "@emailjs/browser"
import Header from "../../../shared/Header/Header"
import s from "./Contacts.module.css"
import { Helmet } from "react-helmet"

const ContactsPage = () => {
  emailjs.init({
    publicKey: 'vAsP69RG-3xyWZrzA',
  })

  interface IFormValues {
    name: string, 
    companyName: string,
    email: string,
    phoneNumber: string,
    city: string,
    message: string
  }

  const { register, handleSubmit, formState } = useForm<IFormValues>({
    defaultValues: {
      name: "", 
      companyName: "",
      email: "",
      phoneNumber: "",
      city: "",
      message: ""
    },
    mode: "onSubmit"
  })

  const onSubmit = (values: IFormValues) => {
    const templateParams = {
      name: values.name,
      company_name: values.companyName, // optional?
      email: values.email,
      phone_number: values.phoneNumber, // optional?
      city: values.city,
      user_message: values.message
    }

    emailjs.send("service_mkj7q3n", "template_ii9sqtj", templateParams)
      .then(() => {
        console.log("Email sent successfully!")
      })
      .catch((err: Error) => {
        console.error("Error occurred during email sending!\n", err)
      })
  }

	return (
		<main className="container">
      <Header />

      <Helmet>
        <title>Контакты</title>
        <meta name="description" content="Хотите связаться? Можете найти наши контакты здесь!" />
      </Helmet>

      <section>
        <h1 className={s.header}>Контакты</h1>
        <div className={s.contacts}>
          <iframe 
            className={s.contacts__map}
            src="https://yandex.ru/map-widget/v1/?um=constructor%3A435d3ab6fa3e793b4bcbc876b0927b309d309eaed21f1eeb468220bd222d10b6&amp;source=constructor"
            frameBorder="none"
            height={300}
          />
          <div className={s.contacts__info}>
            <div className={s.info_item}>
              <h2>Наш офис</h2>
              <span>Россия, Москва, Дубравная улица, 50к5</span>
            </div>
            <div className={s.info_item}>
              <h2>Режим работы</h2>
              <div className={s.opening_hours}>
                <div>ПН - ПТ<br/>09:00 - 18:00</div>
                <div>СБ - ВС<br/>Выходной</div>
              </div>
            </div>
            <div className={s.info_item}>
              <h2>Телефон</h2>
              <span>+7(495) 728-58-21</span>
            </div>
            <div className={s.info_item}>
              <h2>Почта</h2>
              <span>box@decoratoria.ru</span>
            </div>
          </div>
        </div>
      </section>

      <section className={s.contact_us}>
        <h1 className={s.header}>Свяжитесь с нами</h1>
        <form onSubmit={handleSubmit(onSubmit)} className={s.feedbackForm}>
          <input
            {...register("name", {required: true})}
            className={formState.errors?.name ? s.inputError : ""}
            placeholder="Ваше имя"
          />
          <input
            {...register("companyName", {required: true})} // optional?
            placeholder="Название компании"
          />
          <input
            {...register("email", {required: true})}
            className={formState.errors?.email ? s.inputError : ""}
            placeholder="Ваша почта"
          />
          <input
            {...register("phoneNumber", {required: true})} // optional?
            placeholder="Ваш номер телефона"
          />
          <input
            {...register("city", {required: true})}
            className={formState.errors?.city ? s.inputError : ""}
            placeholder="Ваш город"
          />
          <textarea
            {...register("message", {required: true})}
            className={formState.errors?.message ? s.inputError : ""}
            placeholder="Ваше сообщение"
          />
          <button type="submit">Отправить</button>
        </form>
      </section>

		</main>
	)
}

export default ContactsPage
