import { NavLink } from "react-router-dom"
import s from "./NavBar.module.css"
import { useState } from "react"

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false)

	return (
    <nav>
      <div className={`${s.navMenu} ${isOpen ? s.open : ""}`}>
        <NavLink to="/">Главная</NavLink>
        <NavLink to="/constructions">Конструкции</NavLink>
        <NavLink to="/exhibitions">Выставки</NavLink>
        <NavLink to="/interior">Интерьер</NavLink>
        <NavLink to="/projects">Проекты</NavLink>
        <NavLink to="/about-us">О Нас</NavLink>
        <NavLink to="/contacts">Контакты</NavLink>
      </div>

      <button className={s.burger} onClick={() => setIsOpen(prev => !prev)} />
    </nav>
	)
}

export default NavBar
