import { useRef } from "react"
import s from "./CookiesConfirm.module.css"

const CookiesConfirm = () => {

  const cookiesBarRef = useRef<HTMLDivElement>(null)
  const isCookesAllowed = JSON.parse(localStorage.getItem("cookies-allowed") || "false")

  const allowCookies = () => {
      localStorage.setItem("cookies-allowed", "true")
      cookiesBarRef.current!.style.display = "none"
  }

  if (isCookesAllowed) return <></>

	return (
		<div className={s.cookiesBar} ref={cookiesBarRef}>
			<p>Мы используем <a href="#">файлы cookies</a> что бы улучшить сайт для вас</p>
      <button onClick={allowCookies}>Хорошо</button>
		</div>
	)
}

export default CookiesConfirm
