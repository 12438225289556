import { AxiosResponse } from "axios"
import { useEffect, useState } from "react"
import { api } from "../../../../api"
import { IProject } from "../../../../types"
import CardsGrid from "../../../shared/CardsGrid/CardsGrid"
import GridCard from "../../../shared/GridCard/GridCard"
import Header from "../../../shared/Header/Header"
import HeroHeader from "../../../shared/HeroHeader/HeroHeader"
import Loader from "../../../shared/Loader/Loader"
import SectionHeader from "../../../shared/SectionHeader/SectionHeader"
import s from "./Projects.module.css"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet"

const ProjectsPage = () => {
  const [projects, setProjects] = useState<IProject[] | null>(null)

  useEffect(() => {
    api
      .get("/projects")
      .then((res: AxiosResponse<IProject[]>) => {
        setProjects(res.data)
      })
  }, [])

	return (
		<main className="container">
      <Header />

      <Helmet>
        <title>Проекты</title>
        <meta name="description" content="Ознакомьтесь с проектами, которые мы выполнили" />
      </Helmet>

      <HeroHeader>
        Каждый проект требует от нас полного погружения и сосредоточенности на всех поставленных перед нами задачах.<br/>Только дойдя до конца, мы сможем сказать, что выполнили их все.
      </HeroHeader>

      <section className={s.projectsSection}>
        <SectionHeader>
          Проекты
        </SectionHeader>

        {
          (Array.isArray(projects) && projects.length === 0) && (
            <h1 style={{textAlign: "center", fontSize:"2.6rem", fontWeight: "100", marginBlock: "3rem"}}>
              Извините,<br/>Не удалось получить информацию о проектах<br/>Повторите попытку позже
            </h1>
          )
        }

        {
          (projects === null) && <Loader />
        }

        <CardsGrid>
          {projects?.map(project => {
            return (
              <Link to={project.id.toString()} key={project.id}>
                <GridCard
                  img={project.main_photo || "/img/placeholder.jpg"}
                  name={project.name}
                  desc={project.description}
                  location={project.location}
                  year={new Date(project.year)}
                />
              </Link>
            )
          })}
        </CardsGrid>
      </section>
		</main>
	)
}

export default ProjectsPage
