import { Link } from "react-router-dom"
import s from "./ImageCardAccent.module.css"
import Image from "../Image/Image"

interface IProps {
  srcSetObj: {[key: string]: string},
  header: string,
  desc: string,
  to?: string,
  mirrored?: boolean
}

const ImageCardAccent = ({ srcSetObj, header, desc, mirrored, to}: IProps) => {
	return to ? (
    <Link to={to}>
      <div className={`${s.card} ${mirrored ? s.mirrored : ""}`}>
        <Image srcSetObj={srcSetObj}/>
        <div className={s.card__text}>
          <h2>{header}</h2>
          <p>
            {desc}
          </p>
        </div>
      </div>
    </Link>
  ) : (
    <div className={`${s.card} ${mirrored ? s.mirrored : ""}`}>
      <Image srcSetObj={srcSetObj}/>
      <div className={s.card__text}>
        <h2>{header}</h2>
        <p>
          {desc}
        </p>
      </div>
    </div>
  )
}

export default ImageCardAccent
