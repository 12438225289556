import Header from "../../../shared/Header/Header"
import ImageCardAccent from "../../../shared/ImageCardAccent/ImageCardAccent"
import SectionHeader from "../../../shared/SectionHeader/SectionHeader"
import s from "./Interior.module.css"
import ImageHero from "../../../shared/ImageHero/ImageHero"
import { Helmet } from "react-helmet"

const InteriorPage = () => {
	return (
		<div className="container">
			<Header />

      <Helmet>
        <title>Интерьер</title>
      </Helmet>

      <ImageHero
        // img="img/interior/20.jpg"
        srcSetObj={{
          3400: "/img/interior/1@3400.jpg",
          1920: "/img/interior/1@1920.jpg",
          960: "/img/interior/1@960.jpg",
        }}
        paragraphs={[
          "На сегодняшний день главными условиями в интерьере общественных помещений являются лаконичность и удобство. Современный интерьер ориентирован на комфорт посетителя и максимальную функциональность. Также немаловажным фактором является безопасность пространства.",
          "В своей работе мы продумываем всё до мелочей и отвечаем за работу на каждом её этапе. Наши конструкторы в рамках проекта спроектируют все необходимые конструкции, а проектировщики разработают инженерные системы и освещение. Мастера качественно установят и подключат всё оборудование и вам останется только получать максимальное удовлетворение от использования помещения."
        ]}
      />

      <section className={s.interiorSection}>
        <SectionHeader>
          Интерьер
        </SectionHeader>
    
        <ImageCardAccent
          to="commercial-furniture"
          srcSetObj={{
            1500: "/img/interior/2@1500.jpg",
            960: "/img/interior/2@960.jpg",
          }}
          header="Мебель для коммерческих помещений"
          desc="Современный городской пользователь предпочитает удобство, лаконичность и функциональность окружающих его предметов. В первую очередь, это касается мебели в общественных местах. Такая мебель должна быть долговечной, безопасной и максимально удобной при использовании. Также большое значение имеют её конструкторские особенности, позволяющие гармонично вписываться в интерьер и оставлять достаточно места в  окружающем её пространстве"
        />

        <ImageCardAccent
          to="lighting"
          srcSetObj={{
            1500: "/img/interior/5@1500.jpg",
            960: "/img/interior/5@960.jpg",
          }}
          header="Освещение"
          desc="Любое помещение для людей требует грамотной подсветки, которая должна создавать атмосферу заведения или задавать определенное настроение"
        />

        <ImageCardAccent
          to="engineering-systems"
          srcSetObj={{
            3400: "/img/interior/7@3400.jpg",
            1920: "/img/interior/7@1920.jpg",
            960: "/img/interior/7@960.jpg",
          }}
          header="Инженерные системы"
          desc="При установке оборудования в коммерческих помещениях требуется обеспечить его бесперебойную работу и гарантировать стабильное использование"
        />

        <ImageCardAccent
          to="kids-area"
          srcSetObj={{
            3400: "/img/interior/8@3840.jpg",
            1920: "/img/interior/8@1920.jpg",
            960: "/img/interior/8@960.jpg",
          }}
          header="Детская зона"
          desc="Дети такие же полноправные посетители общественных мест, как и взрослые, только требующие внимания к своим особенностям возраста"
        />

      </section>
		</div>
	)
}

export default InteriorPage
