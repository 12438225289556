interface IProps extends Exclude<React.HTMLAttributes<HTMLImageElement>, "srcSet"> {
  srcSetObj: object // key - actual image size, value - path to the image
  // srcSetObj: {[key: string]: string}  
}

const Image = ({srcSetObj, ...props}: IProps) => {
  const srcSet = Object
    .entries(srcSetObj)
    .map(([key, value]) => {
      return `${value} ${key}w`
    })
    .join(", ")  

	return (
		<img
      {...props}
      srcSet={srcSet}
    />
	)
}

export default Image

