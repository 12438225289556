import Header from "../../../shared/Header/Header"
import HeroHeader from "../../../shared/HeroHeader/HeroHeader"
import ImageHero from "../../../shared/ImageHero/ImageHero"
import s from "./AboutUs.module.css"
import ImageCardAccent from "../../../shared/ImageCardAccent/ImageCardAccent"
import { Helmet } from "react-helmet"

const AboutUsPage = () => {
	return (
		<div className="container">
      <Header />

      <Helmet>
        <title>О нас</title>
      </Helmet>

      <HeroHeader>
        Наша компания работает в области застройки выставочных и общественных пространств уже более 15 лет и воплощает в своей работе самые инновационные разработки
      </HeroHeader>

        <ImageHero
          className={s.imageHero}
          srcSetObj={{
            3840: "/img/main/1@1280.jpg",
            960: "/img/main/1@960.jpg"
          }}
          paragraphs={["Мы занимаемся проектированием, строительством, сборкой и установкой любых выставочных конструкций и предметов интерьера для общественных помещений."]}
        />
      <section className={s.cards}>

        <ImageCardAccent
          header="Команда"
          desc="Наш коллектив постоянно совершенствуется и не останавливается на достигнутом, мы каждый день учимся новому и  обмениваемся знаниями и опытом в команде. Наша цель – помочь Вам создать комфортное и продуманное до мелочей пространство!"
          srcSetObj={{
            3840: "/img/main/1@1280.jpg",
            960: "/img/main/1@960.jpg"
          }}
          mirrored
        />
      
        <ImageCardAccent
          header="Разработка"
          desc="Многолетний опыт помогает нам изобретать и конструировать продуманные выставочные конструкции и предметы интерьера, в которых сочетается лаконичность и максимальная практичность. Наши конструкторы постоянно работают над улучшением функциональности и производительности, внедряют в производство новые разработки и идеи"
          srcSetObj={{
            3840: "/img/main/1@1280.jpg",
            960: "/img/main/1@960.jpg"
          }}
          mirrored
        />
      
        <ImageCardAccent
          header="Производство"
          desc="Наше производство имеет разнообразный станочный парк и включает в себя  новейшие станки по дерево- и металлообработке. Мы работаем с такими видами материалов,  как плитные материалы, массив дерева различных пород, металлы (черные и цветные)"
          srcSetObj={{
            3840: "/img/main/1@1280.jpg",
            960: "/img/main/1@960.jpg"
          }}
          mirrored
        />
      
        <ImageCardAccent
          header="Благодарности"
          desc="Наша команда создала не одну выставочную площадку в таких известных местах Москвы и Петербурга, как ВДНХ, музеи Гараж, ГЭС-2, Царицыно и Эрмитаж. Наша работа была высоко оценена организаторами выставок, от многих из них мы получили благодарственные письма"
          srcSetObj={{
            3840: "/img/main/1@1280.jpg",
            960: "/img/main/1@960.jpg"
          }}
          mirrored
        />
      </section>
		</div>
	)
}

export default AboutUsPage
