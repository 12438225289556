import s from "./Main.module.css"
import { Link } from "react-router-dom"
import Header from "../../../shared/Header/Header"
import Image from "../../../shared/Image/Image"
import { Helmet } from "react-helmet"

const MainPage = () => {
	return (
    <main className="container">
      <Header />

      <Helmet>
        <title>50 Max</title>
      </Helmet>

      <Link to="/" className={s.sectionWrapper}>
        <section className={s.hero}>
          <h2 className={s.hero__text}>
            Добро пожаловать на территорию гармонии и порядка
          </h2>
          <Image
            className={s.hero__image}
            srcSetObj={{
              3840: "/img/main/1@1280.jpg",
              960: "/img/main/1@960.jpg"
            }}
          />
        </section>
      </Link>
      
      <Link to="/constructions" className={s.sectionWrapper}>
        <section className={`${s.imageSection} ${s.reversed}`}>
          <div className={s.imageSection__text}>
            <h2>
              Область упорядоченности 
            </h2>
            <p>
              Создавая витрины, мы учитываем все поставленные перед нами задачи, при этом совершенствуя наши технологии с каждым разом. Основой подхода является продуманность, эстетичность, бережное отношение к экспонатам, прочность и безопасность
            </p>
          </div>
          {/* <img className={s.imageSection__image} src="img/main/3.jpg"/> */}
          <Image
            className={s.imageSection__image}
            srcSetObj={{
              3840: "/img/main/3@3840.jpg",
              1920: "/img/main/3@1920.jpg",
              960: "/img/main/3@960.jpg",
            }}
          />
        </section>
      </Link>

      <Link to="/constructions" className={s.sectionWrapper}>
        <section className={s.imageSection}>
          <div className={s.imageSection__text}>
            <h2>
              Область организации пространства
            </h2>
            <p>
              Каждый раз мы ставим перед собой задачу максимально раскрыть мир выставки и позволить замыслу художника отразиться наиболее полно. Как инженеры, понимающие ваши запросы и амбиции, мы воплощаем образы в завершенные формы
            </p>
          </div>
          <Image
            className={s.imageSection__image}
            srcSetObj={{
              2500: "/img/main/5@2500.jpg",
              1920: "/img/main/5@1920.jpg",
              960: "/img/main/5@960.jpg",
            }}
          />
        </section>
      </Link>
      
      <Link to="/interior" className={s.sectionWrapper}>
        <section className={`${s.imageSection} ${s.reversed}`}>
          <div className={s.imageSection__text}>
            <h2>
              Область комфорта
            </h2>
            <p>
              Мы постоянно задаем себе вопрос, как мы можем создать идеальное воплощение для этого конкретного объекта. Мы ищем самые передовые решения, чтобы сделать пространство максимально комфортным и безопасным
            </p>
          </div>
          <Image
            className={s.imageSection__image}
            srcSetObj={{
              3840: "/img/main/4@3840.jpg",
              1920: "/img/main/4@1920.jpg",
              960: "/img/main/4@960.jpg",
            }}
          />
        </section>
      </Link>

      <Link to="/interior" className={s.sectionWrapper}>
        <section className={s.imageSection}>
          <div className={s.imageSection__text}>
            <h2>
              Область удобства
            </h2>
            <p>
              Одно из наших направлений деятельности - обустройство различных зон работы и отдыха.  Имея огромный опыт в этой области, мы каждый раз проектируем и создаем оригинальные предметы мебели, отличающиеся собственным дизайном и интересными решениями 
            </p>
          </div>
          <Image
            className={s.imageSection__image}
            srcSetObj={{
              1500: "/img/main/2@1500.jpg",
              960: "/img/main/2@960.jpg",
            }}
          />
        </section>
      </Link>

      <Link to="/interior" className={s.sectionWrapper}>
        <section className={`${s.imageSection} ${s.reversed}`}>
          <div className={s.imageSection__text}>
            <h2>
              Область света
            </h2>
            <p>
              Используя новейшие разработки и технологии в  области освещения, мы делаем уникальные предметы интерьера, создающие необычную игру света и тени, иллюзию пространства и атмосферы
            </p>
          </div>
          <Image
            className={s.imageSection__image}
            srcSetObj={{
              3840: "/img/main/6@3840.jpg",
              1920: "/img/main/6@1920.jpg",
              960: "/img/main/6@960.jpg",
            }}
          />
        </section>
      </Link>
    </main>
	)
}

export default MainPage
