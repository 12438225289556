import { ReactNode } from "react"
import s from "./CardsGrid.module.css"

interface IProps {
  children: ReactNode
}

const CardsGrid = ({ children }: IProps) => {
	return (
		<div className={s.cards}>
      { children }
		</div>
	)
}

export default CardsGrid
